.registration-style {
  background: radial-gradient(#00abb4, #07767c);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
}

.registration-button {
  color: #FFFFFF !important;
  background-color: #029ba3 !important;
  border-color: #FFFFFF !important;
  margin-left: 10px;
}

.registration-button:hover {
  background-color: #00abb4 !important;
  color: #FFFFFF !important;
  text-decoration-color: #00abb4;
}

.registration-text {
  color: #FFFFFF !important;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  font-size: 2rem;
}

@media (max-width: 768px) {
  .registration-form-container {
    width: 100% !important;
    margin: 20px auto;
  }
  .registration-text {
    color: #FFFFFF !important;
    font-family: inherit;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    font-size: larger;
  }
}

.registration-form-container {
  width: 385px;
  margin: 0px auto !important;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.validation {
  color: #ff9800;
  margin-top: -10px;
  padding-left: 10px;
}

.required-star-registration-style {
  color: #ff0000;
  padding-top: 6px;
  padding-right: 5px;
}
