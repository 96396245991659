.emailconfirmation-style {
  background: radial-gradient(#00abb4, #07767c);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding: 60px;

  .text-muted {
    color: #ff9800 !important;
  }
}

.emailconfirmation-button {
  color: #ffffff !important;
  background-color: #029ba3 !important;
  border-color: #ffffff !important;
  margin-bottom: 10px;
  width: 50%;
  margin-top: 20px;
}

.emailconfirmation-button:hover {
  background-color: #00abb4 !important;
  color: #ffffff !important;
  text-decoration-color: #00abb4;
}

.emailconfirmation-text {
  color: #ffffff !important;
  font-family: inherit;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  font-size: 2rem;
  text-align: -webkit-center;
}

.emailconfirmation-image {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  max-width: 100% !important;
  height: auto;
  box-sizing: border-box;
}
