@import "~bootstrap/scss/bootstrap";

.App {
  height: 100vh;
  font-family: "Noto Sans", sans-serif;
  text-align: center;
  background: #09747a;
}
body {
  background: radial-gradient(#00abb4, #07767c);
}
body.light-theme {
  background: #e0e0e0;
}

.App-header {
  background-color: #00767c;
  background-image: linear-gradient(315deg, #00767c 0%, #028090 74%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.jumbotron {
  margin-top: 50px;
  background-color: snow;
}

hr {
  width: 70%;
}
.btn-light {
  background-color: #ffffff;
  color: #07767c !important;
}
.btn-light:hover {
  color: #ffffff !important;
}

.loginClass {
  background-color: #09747a !important;
}

.white-box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  box-shadow: 1px 4px 14px -5px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}

.white-box h4 {
  margin-bottom: 10px;
}

.white-box a,
.white-box h4, .link-color {
  color: #007b81;
}

.white-box a:hover {
  color: #007b81;
}

.dim-box {
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}

.dim-box h4 {
  margin-bottom: 20px;
}

.dim-box a,
.dim-box h4 {
  color: #007b81;
}

.dim-box a:hover {
  color: #007b81;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.68);
  display: inline-block;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.media-block {
  width: 120px;
  margin: 0 15px 0 0;
  height: 120px;
  border-radius: 0px;
  min-width: 120px;
  min-height: 120px;
}

.media-block img,
.media-block video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

.media-block img.active:hover,
.media-block video.active:hover {
  opacity: 0.6;
}

.img-block img.inactive:hover {
  opacity: inherit;
}

.img-block img {
  width: 120px;
  margin: 0 15px 0 0;
  height: 120px;
  border-radius: 10px;
  min-width: 120px;
  min-height: 120px;
  background-size: cover;
  border: 1px solid #e2e2e2;
  object-fit: cover;
  object-position: center;
  padding: 5px;
}

.img-block.custom-res-60x60 {
  margin-bottom: 0;
}

.img-block.custom-res-60x60 img {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  margin: 0 0 0 0;
  cursor: pointer;
}

.img-block img:hover {
  opacity: 0.6;
}

.img-block img.inactive:hover {
  opacity: inherit;
}

.btn.btn-default {
  background-color: #03959d;
  color: #ffffff;
}

.btn.btn-default:hover {
  background-color: #19abb3;
  color: #ffffff;
}
.toggler > .card {
  margin: 0 !important;
  margin-top: 10px !important;
}

.opacity-1 {
  opacity: 1;
}
.btn.btn-link {
  color: #07767c;
}
.btn.btn-link:hover {
  color: #07767c;
  text-decoration: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding: 15px 0;
}

.simple-modal-footer {
  border-top: 0px solid #dee2e6;
}

.required-star-style {
  color: #ff0000;
  padding-left: 5px;
  padding-top: 6px;
  padding-right: 5px;
}

@media (max-width: 991px) {
  .hide-left-max {
    display: none;
  }
}

@media (min-width: 992px) {
  .hide-left-min {
    display: none;
  }
}

.header-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // margin-bottom: 15px;
}

.app-modal-header {
  color: #007b81;
}

.app-row-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.app-row-direction {
  margin: 15px;
  border-width: 2px;
  border-color: #000;
  color: #f70707;
}
.pointer-cursor {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .long-text {
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    padding-bottom: 2px;
  }
}

@media (max-width: 1500px) {
  .short-text {
    display: inline-block;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    padding-bottom: 2px;
  }
}

@media (max-width: 1200px) {
  .short-text-100 {
    display: inline-block;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    padding-bottom: 2px;
  }
}

@media (max-width: 1200px) {
  .more-text {
    display: inline-block;
    max-width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }
}

.label-text {
  display: inline-block;
  padding: 3px;
  border-radius: 5px;
  margin: 2px 2px 2px 0px;
  font-size: 12px;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  min-width: 100px;
  text-align: center;
}

.child-box {
  border-radius: 10px;
  padding: 12px;
  position: relative;
  margin-top: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-home-fluid {
  max-width: 90%;
  height: auto;
}

.img-icon-fluid {
  max-width: 60%;
  height: auto;
}

.no-records {
  color: #5b5b5b;
  font-size: 16px;
  text-align: center;
}

.light-theme nav:not(.nav-tabs) {
  background-color: #07767c;
}

.btn-dark {
  background-color: #07767c !important;
  width: 100% !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0.3rem 0.3rem 0.4rem rgba(0, 0, 0, 0.2);
}

.fa-btn {
  color: #5b5b5b;
  fontsize: 20;
  cursor: pointer;
}

.fa-btn.inactive {
  color: lightgray;
  cursor: default;
}

.fa-btn.highlight {
  color: #00abb4;
  cursor: default;
}

// .btn-dark:hover,
.btn-light:hover {
  background-color: #00abb4 !important;
  color: #ffffff !important;
  box-shadow: 0.3rem 0.3rem 0.4rem rgba(0, 0, 0, 0.2);
}

.btn-light,
.btn-dark {
  text-align: left;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  box-shadow: none;
}
.btn-dark {
  background-color: #07767c !important;
  width: 100% !important;
  border: 1px solid #ffffff !important;
}
.btn-dark:hover {
  background-color: #ffffff !important;
  box-shadow: 0.3rem 0.3rem 0.4rem rgba(0, 0, 0, 0.2);
}

.btn-light:not(.text-center) svg,
.btn-dark:not(.text-center) svg {
  float: right;
}

.child-btn-light {
  background-color: #ffffff;
  color: #07767c !important;
  text-align: left;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
}

.btn-dark:hover {
  background-color: #00abb4 !important;
  color: #ffffff !important;
}

.picker-popover {
  position: absolute;
}

.color-label {
  border-width: 2px;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 5px;
  border-style: solid;
  border-color: #cccccc;
}

.color-box {
  border-width: 1px;
  border-radius: 5px;
  padding: 5px;
  border-style: solid;
  border-color: #cccccc;
}

.color-box-one {
  border-width: 1px;
  border-radius: 5px;
  padding: 1px;
  border-style: solid;
  border-color: #cccccc;
}

.modal-header {
  color: #5b5b5b;
}

.close-picker-outside-click {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.modal-max-height {
  max-height: 410px;
  overflow-x: hidden;
}

.modal-search {
  border-style: solid;
  border-width: 1px;
  margin: 15px;
  border-color: #ced4da;
  border-radius: 5px;
}

.search-box {
  border-style: solid;
  border-width: 1px;
  margin-top: 10px;
  border-color: #ced4da;
  border-radius: 5px;
}
.modal-top-button {
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.modal-box {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  border-color: #ced4da;
  border-radius: 5px;
}

.masked-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dev-mode {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
}

.nav-tabs .nav-item a {
  padding-left: 10px;
  padding-right: 10px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #17a2b8;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.modal-sm-custom {
  max-width: 254px;
  margin: 1.75rem auto;
}

.center {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.order-status {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.rectangle {
  display: inline-block;
  border: solid #cccccc;
  border-width: 1px;
  width: 24px;
  height: 24px;
  position: relative;
  padding: 2px;
}
.innerCircle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.inline-indicator {
  width: 24px;
  height: 24px;
  position: relative;
  padding: 2px;
  display: inline-flex;
}
.server-rectangle {
  display: inline-block;
  border: solid green;
  margin: 5px;
  background-color: green;
  border-width: 1px;
  border-radius: 5px;
  align-content: center;
  position: relative;
  cursor: pointer;
}

.inline-input-group {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.inline-select {
  color: #000000;
}
.serviceset-holder {
  border-radius: 1px;
  border-color: black;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 10px;
}
.serviceset-button {
  display: flex;
  overflow: hidden;
  flex: 0.3;
}
.serviceset-child {
  display: flex;
  overflow: hidden;
  flex: 1;
}
.day-picker {
  border-color: BLACK;
  border-width: 1px;
  flex: 1;
  font-size: 14px;
  padding-bottom: 5px;
  background-color: #ffffff00;
  color: black;
}
.day-holder {
  flex: 1;
  flex-direction: column;
}
.pointer-cursor-cancel {
  cursor: pointer;
  margin-top: 100;
}
.bf-font-size-12 {
  font-size: 12px;
}
.bf-font-size-11 {
  font-size: 11px;
}

.bf-font-size-16 {
  font-size: 16px;
}
.bf-font-size-14 {
  font-size: 14px;
}
.bf-font-size-45 {
  font-size: 45px;
}
.bf-badge-outline {
  background-color: transparent;
  border-radius: 10px;
  padding: 3px 10px;
  width: fit-content;
}
.bf-badge-outline.bf-badge-light {
  border: 1px solid;
  border-color: inherit;
  color: inherit;
}
