.table td,
.table th {
  vertical-align: middle;
}

.ModalTable {
  width: 95%;
}

.transport-add {
  color: #007b81;
}

.transport-add:hover {
  color: #007b81;
}

.table-row{
  padding: 12px !important
}