.ingredient-add {
  color: #007b81;
}

.ingredient-add:hover {
  color: #007b81;
}

.card-radius{
  border-radius: 10px !important;
}