.add-station {
  color: #007b81;
}

.add-station:hover {
  color: #007b81;
}

.machine-label {
  padding-right: 5px;
}

.popover {
  position: "absolute";
  z-index: "2";
}

.color {
  width: 36px;
  height: 14px;
}
.swatch {
  padding: "5px";
  background: "#FFFFFF";
  border-radius: "1px";
  display: "inline-block";
}
.popover {
  position: "absolute";
  z-index: "2";
}
.cover {
  position: "fixed";
  top: "0px";
  right: "0px";
  bottom: "0px";
  left: "0px";
}
