.forgotpassword-style {
  background: radial-gradient(#00abb4, #07767c);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
}

.forgotpassword-style .forgotpasswordButton {
  color: #FFFFFF !important;
  background-color: #029ba3;
  border-color: #FFFFFF !important;
  margin-bottom: 10px;
}

.forgotpassword-text {
  color: #FFFFFF !important;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  font-size: 2rem;
}

@media (max-width: 768px) {
  .forgotpassword-form-container {
    width: 100% !important;
    margin: 20px auto;
  }
  .forgotpassword-text {
    color: #FFFFFF !important;
    font-family: inherit;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    font-size: larger;
  }
}

.forgotpassword-form-container {
  width: 385px;
  margin: 0px auto !important;
}

.forgotpasswordButton:hover {
  background-color: #00abb4;
  color: #FFFFFF !important;
  text-decoration-color: #00abb4;
  cursor: pointer;
}
