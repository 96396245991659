.white-box .dropdown,
.white-box .dropdown svg {
  margin-top: -15px;
}

.dim-box .dropdown,
.dim-box .dropdown svg {
  margin-top: -15px;
}

.changePassValidation {
  color: #ff9800;
  margin-top: 3px !important;
  font-size: 80%;
  font-weight: 400;
  padding-left: 0px !important;
}

.displayNone {
  display: none;
}

.btn-dark:hover {
  background-color: #00abb4 !important;
  color: #FFFFFF !important;
}

.toggler {
  margin-right: 10px;
}

.profile-row-padding {
  padding-top: 0px;
  padding-bottom: 10px;
}
