// Here you can add other styles
.b-text-editor-wrapper {
  width: 100%;
  display: block;
  margin-bottom: 25px;
  height: 350px;
}
.b-text-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}
.briefing-input-error:focus {
  box-shadow: unset !important;
  outline: unset;
  border: 1px solid red !important;
}
.b-color-red {
  color: red;
}
.b-amount-error {
  bottom: -7px;
  left: 96px;
}
.b-horizontal-slider {
  border: 1px solid grey;
  border-radius: 10px;
  background-color: #fff;
  height: 7px;
  width: 60%;
  display: inline-block;
}

.b-thumb {
  border: 1px solid black;
  background-color: lightgrey;
  outline: none;
  top: -9px;
  height: 22px;
  width: 9px;
  text-align: center;
}
.b-slider-value {
  z-index: 1;
  bottom: -15px;
}
.b-shopping-cart {
  width: 66px;
  height: 66px;
  right: 31px;
  top: 30%;
  bottom: 0;
  right: 14px;
  margin: auto;
  z-index: 99999;
  color: white;
  background: white;
  border-radius: 50%;
}
.b-ingredient-price {
  width: 200px;
}
.b-currency-code {
  left: -40px;
}

.b-default-tag {
  height: 18px;
  width: 18px;
}
.b-disabled {
  user-select: none;
}

.b-error-msg {
  bottom: -30px;
}
.b-common-pagination {
  flex-wrap: wrap;
  nav {
    background-color: #fff !important;
  }
}
.page-item.active .page-link.b-background-green{
  background-color: #07767c;
  box-shadow: unset;
  border-color: #07767c;
  color: #fff;
}
.pagination {
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 0;
  .page-link:focus {
    box-shadow: unset !important;
  }
}

.b_tree {
  list-style-type: none;
  .b_tree_child {
    list-style-type: none;
    padding-left: 0;
    margin-left: -1px;
  }
  input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    ~ {
      ul {
        display: none;
      }
    }
    &:checked {
      ~ {
        ul {
          display: block;
        }
      }
    }
    &:checked {
      ~ {
        label.b_tree_label {
          &:before {
            content: "–";
          }
          &:after {
            border-radius: 0 0 0 0;
            border-top: 1px solid #07767c;
            border-right: 1px solid #07767c;
            border-bottom: 0;
            border-left: 0;
            bottom: 0;
            top: 0.5em;
            height: auto;
          }
        }
      }
    }
  }
  li {
    line-height: 1.2;
    position: relative;
    padding: 0 0 1em 1em;
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -0.5em;
      display: block;
      width: 0;
      border-left: 1px solid #07767c;
      content: "";
    }
    &:last-child {
      &:before {
        height: 1em;
        bottom: auto;
      }
    }
  }
  ul {
    li {
      padding: 1em 0 0 1em;
    }
  }
  > li {
    &:last-child {
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
}
.b_tree_label {
  position: relative;
  display: inline-block;
  background: #fff;
  &:after {
    position: absolute;
    top: 0;
    left: -1.5em;
    display: block;
    height: 0.5em;
    width: 1em;
    border-bottom: 1px solid #07767c;
    border-left: 1px solid #07767c;
    border-radius: 0 0 0 0;
    content: "";
  }
}
label.b_tree_label {
  cursor: pointer;
  margin-bottom: 0;
  &:hover {
    color: #666;
  }
  &:before {
    background: #07767c;
    color: #fff;
    position: relative;
    z-index: 1;
    float: left;
    margin: 0 1em 0 -2em;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    content: "+";
    text-align: center;
    line-height: 0.9em;
  }
  &:after {
    border-bottom: 0;
  }
}
.b_tree_custom {
  display: block;
  background: #eee;
  padding: 1em;
  border-radius: 0;
}

.mbsc-fr-bottom.mbsc-snackbar.mbsc-danger {
  .mbsc-fr-w {
    border-radius: 10px;
    opacity: 0.9;
  }
}
.b-media-list {
  .b-media-list-item {
    .b-placeholder {
      min-height: 120px;
      min-width: 120px;
      border: 1px solid #07767c;
      border-collapse: collapse;
    }
    .b-action-buttons {
      opacity: 0;
      background: rgba(0, 0, 0, 0.3);
      .b-checkbox {
        transform: scale(2);
        -webkit-transform: scale(2);
        padding: 10px;
      }
      .b-icon {
        background: #fff;
        padding: 2px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-right: 10;
        margin-top: 5;
      }
    }
    &:hover,
    &.selected {
      .b-action-buttons {
        opacity: 1;
      }
    }
  }
}

.b-service-set-overview {
  .b-service-set-sticky {
    position: sticky;
    top: 145px;
    z-index: 10;
  }
  .b-service-item-sticky {
    position: sticky;
    top: 165px;
    z-index: 10;
  }
}

.b-box-view {
  .b-left-nav-wrapper,
  .b-right-aside-wrapper,
  .b-main-nav-wrapper,
  .b-main-nav-right {
    display: none !important;
  }
  .b-content-wrapper {
    flex: 1;
    max-width: 100%;
  }

  .b-header-xs-wrapper .navbar-toggler {
    pointer-events: none;
    opacity: 0;
  }
}

.b-box-view.supplier-offering{
  .b-header-wrapper, .b-header-xs-wrapper,
  #fc_frame, #fc_push_frame {
    display: none;
  }
  .position-sticky{
    position: initial !important;
  }
}
