.header-link-common {
  font-weight: 400;
  color: #000000;
  font-size: 16px;
}

.header-link-common a {
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rest-button {
  color: #ffffff;
  background: rgba(1, 1, 1, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding: 6px;
  border-radius: 5px;
  font-size: 16px;
  min-width: 146px;
  text-align: center;
  min-height: 38px;
}

.rest-button:hover {
  color: #6c757d !important;
  background: #ffffff !important;
}

.header-link-common a:focus {
  color: #ffffff !important;
  background: #007b81c7 !important;
}

.brand-logo {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.nav-link {
  display: block;
}

@media (max-width: 340px) {
  .compact-logo {
    min-width: 150px;
  }
}

.settings-icon :hover {
  color: #00abb4;
}

.smart-logo-width {
  max-width: 250px;
  img {
    object-fit: cover;
    min-width: auto;
    width: 100%;
  }
}

.b-header-wrapper {
  margin-bottom: 15px;
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .b-profile-avatar {
    min-width: 0;
    min-height: 0;
    width: 35px;
    height: 35px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    border-width: 2px;
  }
  .navbar > div {
    .navbar-nav {
      .nav-item {
        margin-right: 10px;
        > a {
          padding: 5px 10px;
          border: 0;
          text-align: center;
          &.active,
          &:hover {
            color: rgb(143, 213, 224);
            background: rgba(1, 1, 1, 0.1);
            border-radius: 6px;
          }
        }
      }
    }
  }
}
@media (min-width: 991px) {
  .b-header-wrapper {
    .header-link-common.dropdown-menu {
      right: 0;
      left: auto;
      max-height: 250px;
      overflow: auto;
    }
  }
}
@media (max-width: 991px) {
  .b-header-xs-wrapper {
    text-align: center;
    .navbar > div {
      .navbar-nav {
        .nav-item {
          margin-right: 0;
          > a {
            font-size: 20px;
          }
        }
      }
    }
    .b-profile-avatar {
      min-width: 0;
      min-height: 0;
      width: 35px;
      height: 35px;
    }
  }
}
.child {
  box-shadow: none !important;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
}
