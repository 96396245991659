$GRAY: #dadada;
$BLUE: #1e92f4;
.bf-lang-select {
  .bf-card-bg {
    background: $GRAY;
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
}
