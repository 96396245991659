.loader-container {
  height: 100%;
  vertical-align: middle;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1100;
}

.loader-container > .spinner-border {
  margin-left: auto;
  margin-right: auto;
  color: #FFFFFF;
}
