.carousel-control-next {
  height: 50px;
  width: 30px;
  outline: white;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid white;
  background-color: white;
}

.short-recipe {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 2px;
  margin: 5px 0 !important;
  width: 130px !important;
}

@media (max-width: 991px) {
  .short-recipe {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 2px;
    margin: 5px 0 !important;
    width: 100% !important;
    min-width: 100px;
  }
}

.border-rt {
  border-right: 1px solid #dee2e6;
}

.demo-non-form {
  background-color: red;
}

.mbsc-ios.mbsc-ltr.mbsc-checkbox,
.mbsc-ios.mbsc-ltr.mbsc-radio {
  padding: 0.875em 3.75em 0.875em 0.6em;
}

.order-details-box {
  background: #333333e0 !important;
  margin: 10px;
  border-radius: 10px;
}
