.add-storage {
  color: #007b81;
}

.add-storage:hover {
  color: #007b81;
}

.storage-rack-box {
  padding-bottom: 0;
}

.storage-rack-ellipsis {
  padding-right: 0;
}

.label-block {
  color: #07767c;
  font-size: 18px;
}

.label-block:hover {
  text-decoration: underline;
}

.storage-place-header {
  color: #007b81;
  font-size: 18px;
}
