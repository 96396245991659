.white-box .dropdown,
.white-box .dropdown svg {
  margin-top: -15px;
}
 
.white-box h4 {
  margin-bottom: 10px;
}

.white-box a,
.white-box h4 {
  color: #007b81;
}

.white-box a:hover {
  color: #007b81;
}

.chat-icon {
  position: fixed;
  top: 95%;
  transform: translateY(-50%);
  padding-bottom: 30px;
  color: #00abb4;
}

.chat-icon:hover {
  color: #0000009e  ;
}
