.table td,
.table th {
  vertical-align: middle;
}

.ModalTable {
  width: 95%;
}

.task-add {
  color: #007b81;
}

.top-level-button {
  color: #ffffff;
  margin: 0px;
  background: #07767c;
  border: none;
  margin-bottom: 10px;
}

.top-level-button:hover {
  background: #00abb4;
}

.top-level-button + .top-level-button {
  margin-left: 10px;
}

.top-level-label {
  margin-right: 20px;
  color: #07767c;
}

.container.task-type .task-item-leftbar {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 7px;
  position: absolute;
  padding: 1px;
  float: left;
  height: 100%;
  left: 0;
  top: 0;
}

.container .ingredient-item {
  position: relative;
  margin-top: 5px;
}

.bf-card-component:not(.card-item-button) {
  border: 2px solid #ffffff;
  background: #fff;
  border-radius: 7px;
  color: #000000;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
  position: relative;

  &:hover,
  &.selected {
    background: #07767c;
    color: #ffffff;
  }
  &.selected {
  }
  .bf-btn-wrapper {
    margin-top: -6px;
  }
}
.task-type.card-component,
.intervention-type.card-component,
.ingredient-type.card-component,
.subrecipe-type.card-component {
  padding: 10px;
  background: #e0e0e0;
  border-radius: 7px;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
  color: #000000;
  position: relative;
}

.container .toggler {
  margin-right: 0px;
}

.taskstep-type.card-component,
.interventionstep-type.card-component {
  padding: 10px;
  background: #e0e0e0;
  border-radius: 7px;
  color: #000000;
  position: relative;
  border-style: solid;
  border-color: #ffffff;
  border-width: 2px;
}
.task-type.card-component {
  padding-left: 17px;
}

.ingredient-item {
  margin-left: 0;
}

.container.task-type {
  border: none;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;
  padding-left: 25px;
}

.container.intervention-type {
  padding-right: -20px;
  position: relative;
}

.task-type.card-component:not(.newItemButton),
.ingredient-type.card-component:not(.newItemButton) {
  min-height: 68px;
}

.task-type.assigned.card-component,
.ingredient-type.assigned.card-component {
  background: #ffffff;
}
.task-type.disabled.assigned.card-component:not(.selected):not(.newItemButton) {
  background: #dddddd;
}

.task-type.selected.card-component,
.intervention-type.selected.card-component,
.taskstep-type.selected.card-component,
.interventionstep-type.selected.card-component,
.ingredient-type.selected.card-component,
.task-type.hover.selected.card-component,
.intervention-type.hover.selected.card-component,
.taskstep-type.hover.selected.card-component,
.interventionstep-type.hover.selected.card-component,
.ingredient-type.hover.selected.card-component {
  background: #07767c;
  color: #ffffff;
}

.task-type.assigned.checkbox,
.ingredient-type.assigned.checkbox {
  color: #07767c;
}

.task-type.checkbox:hover,
.ingredient-type.checkbox:hover {
  color: #00abb4;
}

.task-type.assigned.checkbox:hover,
.ingredient-type.assigned.checkbox:hover {
  color: #00abb4;
}

.task-type.checkbox,
.ingredient-type.checkbox {
  color: #5b5b5b;
}

.task-type.hover .task-type.checkbox,
.task-type.hover .task-type.checkbox:hover,
.task-type.assigned.hover .task-type.checkbox:hover,
.ingredient-type.hover .ingredient-type.checkbox,
.ingredient-type.hover .ingredient-type.checkbox:hover,
.ingredient-type.assigned.hover .ingredient-type.checkbox:hover {
  color: #ffffff;
}

.task-type.hover.card-component,
.intervention-type.hover.card-component,
.taskstep-type.hover.card-component,
.interventionstep-type.hover.card-component,
.ingredient-type.hover.card-component,
.ingredient-type.hover.selected.card-component:hover {
  background: #00abb4;
  color: #ffffff;
  transition: background 0.3s;
}

.container .card-component h5 {
  color: #007b81;
}

h5.task-type.selected,
h5.intervention-type.selected,
.task-type.hover h5.task-type,
.intervention-type.hover h5.intervention-type {
  color: #ffffff;
}

h5.container.intervention-type {
  color: #007b81;
}

.interventionContainer {
  margin-right: -60px;
  margin-left: 15px;
}

.container.intervention-type.timing {
  border-radius: 0;
}

.container.intervention-type.newItemButton {
  margin-top: 30px;
}

.intervention-droppable {
  padding: 10px;
  border: 3px solid transparent;
}

.intervention-droppable.draggable {
  min-height: 100px;
  border: 3px solid rgba(7, 118, 124, 0.7);
  background: rgba(7, 118, 124, 0.15);
}

.intervention-droppable.isDraggingOver {
  background: rgba(7, 118, 124, 0.5);
}

.container a:hover {
  color: #007b81;
}

.container a,
.container h4 {
  color: #007b81;
  text-decoration: none;
}

.table td,
.table th {
  vertical-align: top !important;
}

.collapseIcon {
  color: #5b5b5b;
  cursor: pointer;
}

.task-type.selected .intervention-type.collapseIcon,
.task-type.hover .intervention-type.collapseIcon {
  color: #ffffff;
}

.task-type.collapseIcon {
  position: absolute;
  top: 0.6em;
  left: 0px;
}

.container.intervention-type .collapseIcon {
  position: absolute;
  top: 1em;
  left: -25px;
}

.container.task-type.assigned .container.intervention-type .collapseIcon {
  color: white;
}

.modal .ingredient-item-row {
  padding: 10px;
}

.modal .ingredient-item-row.selected {
  color: #ffffff;
  background-color: #007b81;
}

@media (min-width: 576px) {
  .offset-sm-1 {
    margin-left: 0;
  }
}

.add-recipe-ingredient {
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 10px;
  float: right;
  color: #007b81;
  font-size: 18px;
}

.add-recipe {
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 10px;
  float: left;
  color: #007b81;
  font-size: 18px;
}

@media (min-width: 992px) {
  .task-edit-icon {
    margin-top: -25px;
  }
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-body {
  padding: 15px;
}
.disbale-checkbox {
  pointer-events: none;
}
