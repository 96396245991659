.recipe-add {
  color: #007b81;
}

.recipe-add:hover {
  color: #007b81;
}

.card-radius {
  border-radius: 10px !important;
}

.bf-service-item-collapse {
  .card-header {
    background-color: #fff;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
  .img-block img {
    min-width: auto;
    min-height: auto;
    width: 50px;
    height: 50px;
  }
  .bf-accordion-arrow {
    color: grey;
    width: 15px;
  }
  .recipe-dtls {
    min-height: 150px;
  }
}
.bf-sticky-filter {
  background-color: #fff;
  min-height: 40px;
  top: 46px;
  z-index: 999;
  box-shadow: 1px 4px 6px -3px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  @media (max-width: 991px) {
    top: 0;
  }
}
.has-lang-alert ~ .main {
  .bf-sticky-filter {
    top: 98px;
    @media (max-width: 991px) {
      top: 0;
    }
  }
}

.bf-badge-bg {
  background-color: #f9f9f9;
  color: #000;
}
.bf-sticky-filter.isSticky {
  box-shadow: 0px 5px 5px -2px rgba(214, 211, 211, 0.75);
}
.bf-sticky-filter.isSticky h4 {
  font-size: 18px;
}

.bf-recipe-ing-task-card {
  .bf-quantity {
    font-size: 18px;
    font-weight: 500;
  }
  .bf-title {
    font-size: 20px;
    font-weight: 500;
  }
  .card-text,
  .card-title {
    color: #000;
  }
  .checkbox {
    color: #07767c;
  }
  .selected .checkbox {
    color: #fff;
  }
  .bf-clear-image {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    background-color: #fff;
    padding: 2px;
    border-radius: 1px;
  }
}
