.table td,
.table th {
  vertical-align: middle;
}

.ModalTable {
  width: 95%;
}

.unit-add {
  color: #007b81;
}

.unit-add:hover {
  color: #007b81;
}

.modal-dialog.uc-modal {
  max-width: 840px;
}
