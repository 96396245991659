.custom-control {
  z-index: 0;
}
.noUi-horizontal {
  height: 10px !important;
  padding: 0px 16px 0px 5px;
  .noUi-handle {
    width: 25px !important;
    height: 23px !important;
  }
  .noUi-base,
  .noUi-connects {
    z-index: 0 !important;
  }
  .noUi-connect {
    background: #17a2b8 !important;
  }
}
.tag-assign {
  .required-star-style {
    position: absolute;
    top: 0;
  }
}
