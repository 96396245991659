.b-side-navbar{
  .btn-light{
    &:hover{
      box-shadow: none;
    }
  }
}
.border-0 {
  text-align: left;
  border-radius: 8px !important;
  color: #000000;
}

.nav-icon-style {
  margin-top: 5px;
}
