.login-style {
  background: radial-gradient(#00abb4, #07767c);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
}

.login-button {
  color: #FFFFFF !important;
  background-color: #029ba3;
  border-color: #FFFFFF !important;
}

.login-text {
  color: #FFFFFF !important;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  font-size: 2rem;
}

.remember-me {
  color: #FFFFFF;
  text-align: left;
  padding-bottom: 5px;
  padding-right: 0px;
  padding-left: 0px;
}

.link-text {
  color: #FFFFFF !important;
  padding: 5px;
}

@media (max-width: 768px) {
  .login-form-container {
    width: 100% !important;
    margin: 20px auto;
  }
  .login-text {
    color: #FFFFFF !important;
    font-family: inherit;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    font-size: larger;
  }
}

.login-form-container {
  width: 385px;
  margin: 0px auto !important;
}

.login-button:hover, .login-button:focus, .login-button.disabled, .login-button:disabled {
  background-color: #00abb4;
  color: #FFFFFF !important;
  text-decoration-color: #00abb4;
}

.login-style .link-text:hover {
  color: #b8fcf8 !important;
}

.validation {
  color: #ff9800;
  margin-top: -10px;
}

.mbsc-ios.mbsc-input-box.mbsc-control-w,
.mbsc-ios.mbsc-input-outline.mbsc-control-w {
  margin-top: -1px;
  margin: 0em 0em 1em;
  border-radius: 8px;
  border: 1px solid transparent;
}

.mbsc-ios.mbsc-input input {
  height: 2.5em;
  padding: 0 1em;
  background: transparent;
}
