.inline-edit-input {
  font: inherit;
}

.inline-edit-input.input {
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-color: #cccccc;
  border-width: 1px;
}

.inline-edit-input.readonly {

}