.b-border-green {
  border-color: #07767c !important;
}
.b-color-green {
  color: #07767c !important;
}
.b-cursor-none {
  cursor: not-allowed;
  pointer-events: none;
}
.b-cursor-p {
  cursor: pointer;
}
.b-mh-200 {
  max-height: 200px;
}
.b-minHeight-100 {
  min-height: 100px;
}
.b-min-width-150 {
  min-width: 150px !important;
}
.b-sticky-below-header {
  position: sticky;
  top: 58px;
  z-index: 99;
}

.has-lang-alert ~ .main {
  .b-sticky-below-header {
    top: 98px;
  }
}
.b-width-75 {
  width: 75px !important;
}
.b-width-100 {
  width: 100px !important;
}
.b-width-150 {
  width: 150px !important;
}
.b-width-250 {
  width: 250px;
}
.b-icon {
  font-size: 22px;
}

.b-icon-sm {
  width: 16px;
  height: 16px;
}
.b-fill-green {
  filter: invert(52%) sepia(47%) saturate(640%) hue-rotate(82deg)
    brightness(91%) contrast(98%);
}
.b-fill-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(55deg)
    brightness(104%) contrast(103%);
}
.b-pos-rel {
  position: relative !important;
}

.b-object-fit {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
