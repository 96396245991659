.draft-js-wrapper {
    border: 1px solid #ccc;
}
.draft-js-editor {
    padding: 1rem;
}
.rdw-embedded-modal-header-option{
    width: 56%;
}
.public-DraftStyleDefault-block{
    margin: 0;
}
.public-DraftEditor-content{
    min-height: 100px;
}
.rdw-dropdown-selectedtext, .rdw-dropdown-selectedtext:hover{
    color: #212529;
    text-decoration: none;
}
.rdw-link-modal-label{
    margin-top: .1rem;
    margin-bottom: .2rem;
}
.rdw-link-modal-input{
    height: auto;
    margin-top: 0;
    margin-bottom: .1rem;
}
.rdw-link-modal-target-option{
    margin-bottom: .5rem;
}
