.modal-content {
  flex-direction: column !important;
}

.table td,
.table th {
  vertical-align: middle;
}

.ModalTable {
  width: 95%;
}
