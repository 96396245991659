.userverification-style {
  background: radial-gradient(#00abb4, #07767c);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  padding: 60px;
}

.userverificationButton {
  color: #FFFFFF !important;
  background-color: #029ba3 !important;
  border-color: #FFFFFF !important;
  margin-bottom: 10px;
  width: 50%;
  margin-top: 20px;
}

.userverificationText {
  color: #FFFFFF !important;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  font-size: 2rem;
  text-align: -webkit-center;
}

.userverificationButton:hover {
  background-color: #00abb4 !important;
  color: #FFFFFF !important;
  text-decoration-color: #00abb4;
}

.user-verification-icon-style {
  color: #FFFFFF;
}

.user-verification-icon-style:hover {
  color: #FFFFFF;
}

.user-verification-text-muted {
  color: #ff9800 !important;
}
