.card-component {
  padding: 10px;
  background: #e0e0e0;
  border-radius: 7px;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
  color: #000000;
  position: relative;
}

.hover.selected.card-component {
  background: #07767c;
  color: #ffffff;
}

.hover.card-component {
  background: #00abb4;
  color: #ffffff;
  transition: background 0.3s;
}

.container .card-component h5 {
  color: #007b81;
}

.container .card-component h5.selected,
.container .card-component.hover h5 {
  color: #ffffff;
}

.card-item-button.selected,
.card-item-button.hover {
  color: #ffffff;
}

.card-item-button.selected.inactive {
  color: #005053;
}

.card-item-button.hover.inactive {
  color: #006e74;
}