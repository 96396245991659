.compact-logo {
    min-width: 200px;
}

.dropdown img {
    min-width: 48px;
    border-radius: 10px;
    border-color: #e0e0e0;
    border: 1px solid #FFFFFF;
    min-height: 48px;
}

.navbar-nav .nav-item a {
    color: #FFFFFF;
}

.navbar-nav .nav-item a:hover{
    color: #00ABB4;
}

.navbar .form-inline {
    display: inline-block;
    float: left;
}

.navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1) !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.nav-link svg{
    cursor:pointer;
}

a.nav-link{
    font-size: 16px;
}

@media (max-width: 768px){
    .navbar-toggler {
        display: block;
        // margin-bottom: 1rem!important;
    }
    .navbar .form-inline {
        float: none;
    }
}

@media (min-width: 1200px){
    .navbar-nav.rightbar {
        align-items: center;
        .nav-item{
            &:last-child {
                margin-left: auto;
            }
        }
    }
}
