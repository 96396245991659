.bf-add-timing {
  .mbsc-comp {
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding-left: 10px;
  }
}
.bf-time-container {
  height: 36px;
}

.bf-label-color-box{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px; 
  left: -1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
