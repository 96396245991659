.media-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.media-item {
  padding: 20px;
  background: #e0e0e0;
  border: #ffffff solid 3px;
}

.media-item:hover {
  opacity: 0.6;
}

.media-item.selected {
  background: #00abb4;
}

.header-content {
  font-size: 0.8em;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: solid;
  border-width: 2px;
  border-color: #e0e0e0;
  color: #999999
}

.filelist {
  margin-top: 15px;
}