.table td,
.table th {
  vertical-align: middle;
}

.ModalTable {
  width: 95%;
}


.verified-style {
  color: #3d9c3a;
  font-size: 24px;
}

.not-verified-style {
  color: #6c757d;
  font-size: 24px;
}

.user-add {
  color: #007b81;
  font-size: 18px;
}

.user-add:hover {
  color: #007b81;
}

.carousel-control-next {
  height: 50px;
  width: 30px;
  outline: white;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid white;
  background-color: white;
}
