.briefing-lang-btn {
  border: 1px solid black;
  cursor: pointer;
  height: 30px;
  text-align: center;
  width: 30px;
}

.briefing-lang-active {
  background-color: green;
  color: #fff;
}

.briefing-translations .table td {
  border-top: 0;
}
.briefing-input-error {
  border: 1px solid red;
}
