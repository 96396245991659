.page-404-style {
  background: radial-gradient(#00abb4, #07767c);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding: 60px;
}

.page-404-text {
  color: #ffffff !important;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  font-size: 2rem;
  .text-muted {
    color: #ff9800 !important;
  }
}
