
.tm-table-selection-wrapper {
    margin: -10px;
    justify-content: space-between;
    .tm-table-box {
        min-width: 200px;
        padding: 10px 10px;
        border-radius: 4px;
        border: 1px solid #07767c;
        background-color: rgba(7, 118, 124, 0.1);
        cursor: pointer;
        min-width: 200px;
        margin: 10px;
        &:hover {
            background-color: rgba(7, 118, 124, 0.1);
        }
    }
  }
